<!--用户列表-->
<template>
    <div class="userlist">
        <div class="btns">
            <el-button class="add" icon="iconfont icontianjia1" @click="handleAdd">
                新增
            </el-button>
            <el-button class="del" icon="iconfont iconlajitong" @click="handleDel">
                删除
            </el-button>
        </div>
        <el-table
            border
            stripe
            :data="tableList"
            ref="multipleTable"
            :highlight-current-row="true"
            v-loading="loading"
            height="calc(100% - 0.7rem)"
        >
            <el-table-column
                type="selection"
                width="50"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="用户ID"
                prop="User_Id"
                width="320"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="登录名"
                prop="Login_Name"
                width="130"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="用户名"
                prop="User_Name"
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="描述"
                prop="Desc"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="创建时间"
                prop="Create_Time"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
        </el-table>
        <div class="table-page">
            <div class="el_right">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[20,40,60,80,100]"
                    :page-size="limit"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
export default {
    name: 'userlist',
    data() {
        return {
            deptid: '',
            tableList: [],
            page: 1,
            limit: 20,
            total: 0,
            loading: false,
        };
    },
    watch: {},
    created() {
        eventBus.$on('load-user-table', deptid => {
            this.deptid = deptid;
            this.getUserList();
        });
    },
    methods: {
        // 获取用户列表
        async getUserList() {
            this.loading = true;
            const url = `/interfaceApi/basicplatform/deptusers/${this.deptid}?page=${this.page}&rows=${this.limit}&search=`;
            const data = await this.$axios.get(url);
            this.tableList = data.List;
            this.total = data.PageCount;
            this.loading = false;
        },
        // 新增用户
        handleAdd() {
            if (!this.deptid) {
                this.$message.warning('请先选择部门');
                return;
            }
            const addTableData = {
                showCheckBox: true,
                headerData: [{
                    title: '用户ID',
                    field: 'User_Id',
                }, {
                    title: '用户名',
                    field: 'User_Name',
                }, {
                    title: '登录名',
                    field: 'Login_Name',
                }, {
                    title: '描述',
                    field: 'Desc',
                }, {
                    title: '创建时间',
                    field: 'Create_Time',
                }],
                requestUrl: '/basicplatform/deptusers/selectuser',
                requestParameter: '&deptid=' + this.deptid,
            };
            this.$toast({
                title: true,
                text: '添加用户',
                type: 'eject',
                width: '15rem',
                height: '75%',
                viewPosition: 'components',
                t_url: 'table/addTable',
                extr: {
                    tips: '添加用户',
                    tableData: addTableData,
                    sureCallBack: this.addUserCallBack,
                },
            });
        },
        // 添加用户
        addUserCallBack(data = []) {
            const userids = data.map(item => item.User_Id).join();
            this.$axios
                .post(`/interfaceApi/basicplatform/deptusers/adduser?deptid=${this.deptid}&userids=${userids}`)
                .then(res => {
                    if (res) {
                        this.$message.success('操作成功');
                        this.getUserList();
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除选中项目
        handleDel() {
            const selectData = this.$refs.multipleTable.selection || [];
            if (selectData.length === 0) {
                this.$message.warning('请先选择用户');
                return;
            }
            const userids = selectData.map(item => item.User_Id);
            this.$confirm('是否删除？', '确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                this.loading = true;
                const tenantId = this.$takeTokenParameters('TenantId');
                const deleteDate = {
                    TenantId: tenantId,
                    OrgId: this.deptid,
                    UserIds: userids,
                };
                const row = await this.deleteInstitutionalRequst(deleteDate);
                if (row) {
                    this.$message({
                        showClose: true,
                        message: '操作成功',
                        type: 'success',
                    });
                    this.getUserList();
                } else {
                    this.loading = false;
                }
            });
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getUserList();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getUserList();
        },

        // 删除请求
        deleteInstitutionalRequst(deleteDate) {
            return this.$axios.$delete('/interfaceApi/platform/tenant_orguser_manage/delete', deleteDate, { defEx: true });
        },
    },
    destroyed() {
        eventBus.$off('load-user-table');
    },
};
</script>

<style lang="stylus" scoped>
.userlist
    height 100%;
    padding 0.2rem;
    .el-table
        margin-top 0.1rem;
        >>> th
            padding 0.08rem 0;
            background: #EDF0F5;
            border: 1px solid #DEDFE0;
            color #022782
        th,td
            padding 0
    .btns
        display flex
        .el-button
            display flex
            justify-content center
            align-items center
            font-size 0.16rem;
            color #fff
            width 0.8rem;
            height 0.32rem;
            margin-right 0.1rem;
            &.add
                background #1842AA
            &.del
                background #E93D4B
</style>
