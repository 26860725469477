<!--部门管理-->
<template>
    <div class="orgManage">
        <!--        左侧菜单列表-->
        <div class="panel-left">
            <slider></slider>
        </div>
        <!--        右侧用户列表-->
        <div class="pannel-right">
            <userlist></userlist>
        </div>
    </div>
</template>

<script>
import slider from './slider';
import userlist from './userlist';
export default {
    name: 'org-manage',
    components: { slider, userlist },

};
</script>

<style lang="stylus" scoped>
.orgManage
    display flex
    width 100%;
    height 100%;
    background #F0F0F0;
    .panel-left
        width 6rem;
        background #fff;
    .pannel-right
        margin-left 0.2rem;
        flex 1;
        background #fff
</style>
